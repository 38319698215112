import React from "react"

// Components
import Seo from "components/seo"

// Page Sections
import WebinarVideo from "./../screens/webinar-replay/webinar-video"
import BodyLanding from "./../screens/webinar-replay/2023-10-19-chao/body-landing"

const WebinarView = () => {
	return (
		<React.Fragment>
			<WebinarVideo
				videoTitle="Recognizing testing pitfalls and optimizing test selection for common infectious diseases in Urgent Care clinics"
				videoDisplayTitle="Recognizing testing pitfalls and optimizing test selection for common infectious diseases in Urgent Care clinics"
				vimeoLink="https://player.vimeo.com/video/877753053?h=b3e6758ad8" 
				placeholderImage="2023-10-19-chao-webinar-replay.jpg"
				localStorageID="2023-10-19-chao-webinar-replay"
				pageSlug="/webinar-replay/2023-10-19-chao/"
			/>
			<BodyLanding pageName="download" localStorageID="2023-10-19-chao-webinar-replay" />
		</React.Fragment>
	)
}

export default WebinarView

export const Head = () => (
  <Seo
  title="Webinar: Recognizing testing pitfalls and optimizing test selection for common infectious diseases in Urgent Care clinics"
  description="Recognizing testing pitfalls and optimizing test selection for common infectious diseases in Urgent Care clinics"
  image="/meta/webinar-chao-2023-10-19.jpg"
/>
)
